import { apiGet, apiPost } from './service'

export async function listInverterBroken(data) {
	return await apiPost("/api/InverterBroken/list", data);
}

export async function fullListInverterBroken(data) {
	return await apiPost("/api/InverterBroken/fulllist", data);
}

export function getInverterBroken(data) {
	let url = "/api/InverterBroken/read/" + data;
	return apiGet(url, {params: {timestamp: Math.random()}});
}

export function postInverterBroken(data) {
	return apiPost("/api/InverterBroken/edit", data);
}

export function addInverterBroken(data) {
	return apiPost("/api/InverterBroken/add", data);
}

export function deleteInverterBroken(data) {
	return apiGet("/api/InverterBroken/delete/" + data);
}
